'use strict'

import 'flatpickr/dist/flatpickr.css'
import flatpickr from 'flatpickr'
import { German } from 'flatpickr/dist/l10n/de'
import { Dutch } from 'flatpickr/dist/l10n/nl'
import { english } from 'flatpickr/dist/l10n/default'
import { French } from 'flatpickr/dist/l10n/fr.js'
import { Italian } from 'flatpickr/dist/l10n/it'
import { Spanish } from 'flatpickr/dist/l10n/es'
import { CustomLocale, Locale } from 'flatpickr/dist/types/locale'
import { BaseOptions as CalendarOptions } from 'flatpickr/dist/types/options'
import { Instance as CalendarInstance } from 'flatpickr/dist/types/instance'
import { Gesture } from '@use-gesture/vanilla'

import * as coreCommon from 'assets/core/js/common'
import SelectCustom from '@campings-group/design-system/src/design/objects/select-custom/twig/assets'
import elementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'
import MobileBottomPanel from '@ui/MobileBottomPanel/component'
import type { MobileBottomPanelType } from '@ui/MobileBottomPanel/component'

type PluginConfig<T = unknown> = T

type PluginsList = (typeof pluginsList)[number]

export type PluginsConfig<T = unknown> = (calendar: Calendar) => PluginConfig<T>

export interface CalendarPlugin {
  calendar: Calendar
}

export interface CalendarConfig {
  callbacks?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key in (typeof callbacksAvailable)[number]]?: ((this: void, calendar: Calendar, ...args: any[]) => void)[]
  }
  numberOfMonths?: number
  currentLocale?: string[]
  closeOnDateChange?: boolean
  withNights?: boolean
  pluginsConfig?: Partial<Record<PluginsList, PluginsConfig>>
}

const pluginsList = ['flexibleDate', 'nightsSelection', 'refreshDates'] as const

const callbacksAvailable = ['onChange', 'onOpen', 'onClose', 'onDayOver', 'onDayOut', 'onMonthChange'] as const

const languagesMapping: Record<string, CustomLocale | Locale> = {
  de: German,
  en: english,
  es: Spanish,
  fr: French,
  it: Italian,
  nl: Dutch,
}

export class Calendar {
  element!: HTMLElement
  input!: HTMLInputElement
  inputAlt!: HTMLInputElement
  config!: CalendarConfig
  isInit!: boolean
  isVisible!: boolean
  calendar!: CalendarInstance
  locale!: string
  mobilebottomPanel!: MobileBottomPanelType
  gesture!: Gesture
  plugins!: CalendarPlugin[]

  constructor(element: string | HTMLElement, userConfig?: CalendarConfig) {
    this.element = this.resolveElement(element)

    this.config = userConfig ?? {}

    if (!this.config?.currentLocale) {
      return
    }

    if (!this.element.hasAttribute('data-field-id')) {
      return
    }

    const inputElement = document.querySelector<HTMLInputElement>(`#${this.element.getAttribute('data-field-id') as string}`)

    if (!inputElement) {
      return
    }

    this.input = inputElement

    this.isInit = false
    this.isVisible = false

    this.initConfig()
    this.initCalendar()
    this.initEvents()
    this.initFeatures()
    this.initMobilePanel()
  }

  private resolveElement<T extends HTMLElement>(element: string | HTMLElement): T {
    let el: HTMLElement | string | null = element

    if (typeof element === 'string') {
      el = document.querySelector<T>(element)
    }

    if (!el || typeof el === 'string') {
      throw new Error('Missing element.')
    }

    return el as T
  }

  getDate(): string {
    return this.input.value
  }

  setDate(date: string): void {
    this.calendar.setDate(date, false, 'Y-m-d')
    this.input.value = date
    this.inputAlt.value = this.formatDate(new Date(date))
  }

  open(): void {
    if (this.isVisible) {
      return
    }

    this.element.removeAttribute('hidden')
    this.isVisible = true

    this.input.dispatchEvent(new CustomEvent('calendar.open'))

    if (this.config.callbacks?.onOpen) {
      this.config.callbacks.onOpen.forEach((cbFn) => {
        cbFn(this)
      })
    }
  }

  close(): void {
    if (!this.isVisible) {
      return
    }

    this.element.setAttribute('hidden', 'hidden')
    this.isVisible = false

    this.input.dispatchEvent(new CustomEvent('calendar.close'))

    if (this.config.callbacks?.onClose) {
      this.config.callbacks.onClose.forEach((cbFn) => {
        cbFn(this)
      })
    }
  }

  initCalendar(): void {
    const config: Partial<CalendarOptions> = {
      // do not use flatpickr altInput as we want to have a custom format and the library does not allow it
      altInput: false,
      monthSelectorType: 'static',
      dateFormat: 'Y-m-d',
      disableMobile: true,
      nextArrow: '',
      prevArrow: '',
      inline: true,
      showMonths: coreCommon.isMobile() || coreCommon.isTablet() ? 1 : 2,
      onMonthChange: () => {
        this.config.callbacks?.onMonthChange?.forEach((cbFn) => {
          cbFn(this)
        })
      },
      onReady: () => {
        if (this.input.value) {
          this.inputAlt.value = this.formatDate(new Date(this.input.value))
        }
      },
      onDayCreate: (dObj: unknown, dStr: string, fp: CalendarInstance, dayElem: Element) => {
        if (fp.config.enable && !dayElem.classList.contains('disabled')) {
          dayElem.classList.add('enabled')
        }
      },
      onChange: (selectedDates: Date[], dateStr: string, instance: CalendarInstance) => {
        if (selectedDates[0] && this.locale) {
          // @ts-ignore: altinput is always set
          this.inputAlt.value = this.formatDate(selectedDates[0])

          if (this.config.callbacks?.onChange && instance) {
            this.config.callbacks.onChange.forEach((cbFn) => {
              cbFn(this, dateStr, this.inputAlt.value)
            })
          }
        }
      },
    }

    if (this.config.numberOfMonths) {
      config.showMonths = this.config.numberOfMonths
    }

    if (this.element.hasAttribute('data-select-all-days') === false) {
      // @ts-ignore fp_incr is defined by flatpickr
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      config.minDate = new Date().fp_incr(1)
    }

    const currentYear = new Date().getFullYear()

    if (this.input.hasAttribute('data-mindate')) {
      const [month, day] = this.input.getAttribute('data-mindate')?.split('-') as string[]
      config.minDate = `${currentYear}-${Number(month)}-${Number(day)}`
    }

    if (this.input.hasAttribute('data-maxdate')) {
      const [month, day] = this.input.getAttribute('data-maxdate')?.split('-') as string[]
      config.maxDate = `${currentYear}-${Number(month)}-${Number(day)}`
    }

    if (this.config.currentLocale) {
      config.locale = languagesMapping[this.config.currentLocale[0] as string] ?? 'fr'
    }

    config.appendTo = this.element.querySelector(`#${this.element.id}-wrapper`) as HTMLElement

    const altInput = document.createElement('input')
    altInput.placeholder = this.input.placeholder ?? ''
    altInput.className = this.input.className ?? ''
    altInput.required = this.input.required ?? false
    altInput.readOnly = true
    altInput.tabIndex = 0
    altInput.type = 'text'
    altInput.id = `${this.input.id}-alt`

    this.inputAlt = this.input.parentNode?.insertBefore<HTMLInputElement>(altInput, this.input) as HTMLInputElement

    this.inputAlt.addEventListener('click', () => {
      this.open()
    })

    this.calendar = flatpickr(this.input, config)

    this.input.type = 'hidden'

    if (this.input.hasAttribute('data-panel-target')) {
      this.inputAlt.setAttribute('data-panel-target', this.input.getAttribute('data-panel-target') as string)
    }

    // update the label assodicated with the field to point to the alt input
    const label = document.querySelector(`label[for=${this.input.id}]`)

    if (label) {
      label.setAttribute('for', `${this.input.id}-alt`)
      this.inputAlt.setAttribute('id', `${this.input.id}-alt`)
    }

    this.locale = 'fr-FR'

    if (this.config.currentLocale) {
      this.locale = `${this.config.currentLocale[0] as string}-${this.config.currentLocale[1] as string}`
    }

    this.isInit = true
  }

  formatDate(date: Date): string {
    if (isNaN(date.getTime())) {
      return ''
    }

    return Intl.DateTimeFormat(this.locale).format(date)
  }

  private initConfig(): void {
    if (typeof this.config.closeOnDateChange === 'undefined') {
      this.config.closeOnDateChange = true
    }

    if (typeof this.config.callbacks === 'undefined') {
      this.config.callbacks = {}
    }

    callbacksAvailable.forEach((cb) => {
      if (typeof this.config.callbacks?.[cb] === 'undefined') {
        // @ts-ignore
        this.config.callbacks[cb] = []
      }
    })
  }

  private initMobilePanel(): void {
    const panelEl = this.element.closest<HTMLElement>('.dca-mobile-bottompanel__calendar')

    if (!panelEl) {
      return
    }

    const panelId = panelEl.id

    this.mobilebottomPanel = MobileBottomPanel(`#${panelId}`)

    this.inputAlt.addEventListener('click', () => {
      this.mobilebottomPanel.open()
    })

    this.config.callbacks?.onClose?.push(() => {
      if (coreCommon.isMobile()) {
        this.mobilebottomPanel.close()
      }
    })
  }

  private initFeatures(): void {
    if (!this.config.pluginsConfig) {
      this.config.pluginsConfig = {}
    }

    if (this.element.hasAttribute('data-features')) {
      const activePlugins = this.element.getAttribute('data-features')?.split(',') as PluginsList[]

      activePlugins.forEach((plugin) => {
        if (!this.config.pluginsConfig?.[plugin]) {
          // @ts-ignore
          this.config.pluginsConfig[plugin] = () => ({})
        }
      })
    }

    this.plugins = []
    const pluginsKeys = Object.keys(this.config.pluginsConfig) as PluginsList[]

    pluginsKeys.forEach((key) => {
      const pluginConfigFn = this.config.pluginsConfig?.[key]

      if (!pluginConfigFn) {
        return
      }

      switch (key) {
        case 'flexibleDate':
          this.plugins.push(new FlexibleDatePlugin(this, pluginConfigFn(this) as FlexibleDatePlugin['config']))
          break
        case 'nightsSelection':
          this.plugins.push(new NightsSelectionPlugin(this, pluginConfigFn(this) as NightsSelectionPlugin['config']))
          break
        case 'refreshDates':
          this.plugins.push(new RefreshDatesPlugin(this, pluginConfigFn(this) as RefreshDatesPlugin['config']))
          break
      }
    })
  }

  private initEvents(): void {
    this.element.addEventListener('mouseover', (e) => {
      const target = e.target as HTMLElement

      if (target.classList.contains('flatpickr-day')) {
        let daysAfter = Array.from(this.calendar.calendarContainer.querySelectorAll<HTMLElement>('.flatpickr-day:not(.hidden)'))

        const indexOfElement = daysAfter.indexOf(target)
        if (indexOfElement !== -1) {
          daysAfter = daysAfter.splice(indexOfElement, daysAfter.length - 1)
        }

        daysAfter.shift()

        if (this.config.callbacks?.onDayOver) {
          this.config.callbacks.onDayOver?.forEach((cbFn) => {
            cbFn(this, target, daysAfter)
          })
        }
      }
    })

    this.element.addEventListener('mouseout', (e) => {
      const target = e.target as HTMLElement

      if (target.classList.contains('flatpickr-day')) {
        if (this.config.callbacks?.onDayOut) {
          this.config.callbacks.onDayOut?.forEach((cbFn) => {
            cbFn(this, target)
          })
        }
      }
    })

    this.gesture = new Gesture(
      this.element.querySelector<HTMLElement>('.search-calendar__wrapper') as HTMLElement,
      {
        onDragEnd: (state) => {
          if (!coreCommon.isMobile()) {
            return
          }

          if (state.direction[0] === -1) {
            this.calendar.changeMonth(1)
          } else if (state.direction[0] === 1) {
            this.calendar.changeMonth(-1)
          }
        },
      },
      {
        drag: {
          axis: 'x',
        },
      }
    )

    this.element.addEventListener('clear', () => {
      this.calendar.clear()
    })

    const closeFn = (e: MouseEvent): void => {
      if (this.isVisible && e.target !== this.inputAlt && e.target !== this.input.parentNode && !this.element.contains(e.target as HTMLElement)) {
        this.close()
      }
    }

    this.config.callbacks?.onOpen?.push(() => {
      document.body.addEventListener('click', closeFn)
    })

    this.config.callbacks?.onClose?.push(() => {
      document.body.removeEventListener('click', closeFn)
    })

    this.config.callbacks?.onChange?.push(() => {
      if (!this.config.closeOnDateChange) {
        return
      }

      this.close()
    })
  }

  removeCalendar(): void {
    this.calendar?.destroy()
  }

  destroy(): void {
    this.calendar?.destroy()
    this.plugins = []
  }
}

export class NightsSelectionPlugin implements CalendarPlugin {
  calendar!: Calendar
  selectElement!: SelectCustom
  otherSelectElement!: SelectCustom
  value!: number
  config!: PluginConfig<{
    onValueChange?: (value: number) => void
  }>

  constructor(calendar: Calendar, config = {}) {
    this.calendar = calendar
    this.config = config

    this.initElements()
    this.initEvents()
    this.updateDate()

    this.calendar.formatDate = (date: Date): string => {
      if (isNaN(date.getTime())) {
        return ''
      }

      return this.formatDate(date)
    }
  }

  updateDate(): void {
    const date = this.calendar?.input.value

    if (date === '') {
      return
    }

    const text = this.formatDate(date)

    if (this.calendar.inputAlt) {
      this.calendar.inputAlt.value = text
    }
  }

  formatDate(date: Date | string): string {
    const startDate = new Date(date)
    const endDate = new Date(date)
    const fieldText = this.calendar.element.getAttribute('data-field-text')?.split('|') as string[]
    const nightsValue = this.value

    if (this.selectElement && this.selectElement.values[0]) {
      endDate.setDate(endDate.getDate() + parseInt(this.selectElement.values[0], 10))
    }

    if (startDate.toISOString().split('T')[0] === endDate.toISOString().split('T')[0]) {
      return Intl.DateTimeFormat(this.calendar.locale).format(startDate)
    }

    let datesText = `${startDate.toLocaleString(this.calendar.locale, { day: 'numeric' })} - ${endDate.toLocaleString(this.calendar.locale, {
      day: 'numeric',
    })} ${endDate.toLocaleString(this.calendar.locale, { month: 'short' })} ${endDate.toLocaleString(this.calendar.locale, { year: 'numeric' })}`

    if (startDate.getMonth() !== endDate.getMonth()) {
      datesText = `${startDate.toLocaleString(this.calendar.locale, { day: 'numeric' })} ${startDate.toLocaleString(this.calendar.locale, {
        month: 'short',
      })} - ${endDate.toLocaleString(this.calendar.locale, { day: 'numeric' })} ${endDate.toLocaleString(this.calendar.locale, {
        month: 'short',
      })} ${endDate.toLocaleString(this.calendar.locale, { year: 'numeric' })}`
    }

    let text = nightsValue > 1 ? (fieldText[1] as string) : (fieldText[0] as string)
    text = text.replace('%dates%', datesText)
    text = text.replace('%count%', nightsValue.toString())

    return text
  }

  private initElements(): void {
    const selectCustomElement = this.calendar.element.querySelector<HTMLDetailsElement>(`#${this.calendar.element.id}-nights-select`)
    const otherSelectCustomElement = this.calendar.element.querySelector<HTMLDetailsElement>(`#${this.calendar.element.id}-other-nights-select`)

    if (!selectCustomElement) {
      return
    }

    // retrieve the select inside each select custom so we can later bind the select custom instances to them
    const selectElement = selectCustomElement.querySelector<HTMLSelectElement>('summary select') as HTMLSelectElement
    const otherSelectElement = otherSelectCustomElement?.querySelector<HTMLSelectElement>('summary select') as HTMLSelectElement

    const nightsChipEl = selectCustomElement.closest('.search-calendar__nights-choice')
    const otherNightsChipEl = otherSelectCustomElement?.closest('.search-calendar__nights-others')

    if (!nightsChipEl) {
      return
    }

    if (!elementPropertiesManager.hasProperty(selectElement, 'selectCustom')) {
      this.selectElement = new SelectCustom(selectCustomElement, {
        onValuesSet: (values: string[]): void => {
          const selectedValue = values[0]

          if (!selectedValue) {
            return
          }

          this.value = Number(selectedValue)

          // unset all preset chips
          this.calendar.element
            .querySelectorAll<HTMLElement>('.search-calendar__nights .search-calendar__nights-preset')
            .forEach((el) => el.removeAttribute('data-focused'))

          // get the chip matching the value of the select custom
          const el = this.calendar.element.querySelector(`.search-calendar__nights .search-calendar__nights-preset[data-nights="${this.value}"]`)

          el?.setAttribute('data-focused', 'true')

          if (values.length > 0) {
            nightsChipEl.setAttribute('data-focused', 'true')

            // in case the calendar wrapper is hidden, remove the attribute
            const calendarWrapper = this.calendar.element.querySelector(`#${this.calendar.element.id}-wrapper`) as HTMLElement
            calendarWrapper.removeAttribute('hidden')
          } else {
            nightsChipEl.removeAttribute('data-focused')
          }

          this.config.onValueChange && this.config.onValueChange(this.value)
          this.updateDate()
          // synchronize the value of this select with the one holding other values
          if (this.otherSelectElement && this.otherSelectElement.values[0] !== this.selectElement.values[0]) {
            this.otherSelectElement && this.otherSelectElement.setValues(values)
          }
        },
      })
      elementPropertiesManager.addProperty<SelectCustom>(selectElement, 'selectCustom', this.selectElement)
    } else {
      this.selectElement = elementPropertiesManager.getProperty<SelectCustom>(selectElement, 'selectCustom') as SelectCustom
    }

    if (otherNightsChipEl && otherSelectCustomElement) {
      if (!elementPropertiesManager.hasProperty(otherSelectElement, 'selectCustom')) {
        this.otherSelectElement = new SelectCustom(otherSelectCustomElement, {
          onValuesSet: (values: string[]) => {
            if (values.length > 0) {
              // synchronize the value of this select with the one holding all values
              if (this.selectElement && values[0] !== this.selectElement.values[0]) {
                this.selectElement.setValues(values)
              }

              otherNightsChipEl.setAttribute('data-focused', 'true')
            } else {
              otherNightsChipEl.removeAttribute('data-focused')
            }
          },
        })
        elementPropertiesManager.addProperty<SelectCustom>(otherSelectElement, 'selectCustom', this.otherSelectElement)
      } else {
        this.otherSelectElement = elementPropertiesManager.getProperty<SelectCustom>(otherSelectElement, 'selectCustom') as SelectCustom
      }
    }
  }

  private initEvents(): void {
    this.calendar.element.querySelectorAll<HTMLElement>('.search-calendar__nights .search-calendar__nights-preset').forEach((el) => {
      el.addEventListener('click', () => {
        const value = el.getAttribute('data-nights') as string

        this.selectElement.setValues([value])
        this.otherSelectElement?.setValues([])
      })
    })

    this.calendar.config.callbacks?.onDayOver?.push((calendar, target, daysAfter: HTMLElement[]) => {
      const nights = parseInt(this.selectElement.values[0] as string, 10)

      daysAfter.slice(0, nights).forEach((el) => {
        el.setAttribute('data-highlighted', 'true')
      })
    })

    this.calendar.config.callbacks?.onDayOut?.push((calendar) => {
      calendar.calendar.calendarContainer.querySelectorAll('[data-highlighted]').forEach((dayEl) => {
        dayEl.removeAttribute('data-highlighted')
      })
    })
  }
}

export class FlexibleDatePlugin implements CalendarPlugin {
  calendar!: Calendar
  config!: PluginConfig<object>

  constructor(calendar: Calendar, config = {}) {
    this.calendar = calendar
    this.config = config

    this.initEvents()
  }

  private initEvents(): void {
    this.calendar.element.querySelectorAll('.search-calendar__flexible-dates button[aria-controls]').forEach((el) => {
      const targetEl = this.calendar.element.querySelector<HTMLInputElement>(`#${el.getAttribute('aria-controls') as string}`)

      if (!targetEl) {
        return
      }

      el.addEventListener('click', () => {
        if (el.hasAttribute('data-focused')) {
          return
        }

        const clickedButtonEl = this.calendar.element.querySelector('.search-calendar__flexible-dates button[aria-controls].dca-chip[data-focused]')

        if (clickedButtonEl) {
          clickedButtonEl.removeAttribute('data-focused')
        }

        el.setAttribute('data-focused', 'true')

        targetEl.click()
      })
    })
  }
}

export class RefreshDatesPlugin implements CalendarPlugin {
  calendar!: Calendar
  isPluginInit!: boolean
  currentXhrRequest!: XMLHttpRequest | null
  config!: PluginConfig<{
    ajaxUrl?: string
    ajaxData?: Record<string, string | number> | (() => Record<string, string | number>)
    firstDate?: string
  }>

  constructor(calendar: Calendar, config = {}) {
    this.calendar = calendar
    this.config = config
    this.currentXhrRequest = null
    this.isPluginInit = false

    this.initEvents()
  }

  private initEvents(): void {
    this.calendar.config.callbacks?.onOpen?.push(() => {
      this.updateAvailableDates()
    })

    this.calendar.config.callbacks?.onMonthChange?.push(() => {
      this.updateAvailableDates()
    })
  }

  updateAvailableDates(): void {
    if (!this.calendar.isVisible) {
      return
    }

    // handle a rare case when the calendar is destroyed but a call to this method is still started
    if (!this.calendar.calendar || !this.calendar.calendar.config || !this.config.ajaxUrl) {
      return
    }

    this.config.firstDate =
      this.config.firstDate && !this.isPluginInit
        ? this.config.firstDate
        : `${this.calendar.calendar.currentYear}-${('0' + (this.calendar.calendar.currentMonth + 1)).slice(-2)}-01`

    let ajaxData: Record<string, string> =
      typeof this.config.ajaxData === 'function'
        ? (this.config.ajaxData as () => Record<string, string>)()
        : (this.config.ajaxData as Record<string, string>)

    if (!ajaxData) {
      ajaxData = {}
    }

    ajaxData.startDate = this.config.firstDate

    if (!this.isPluginInit && this.calendar.calendar.config) {
      this.calendar.calendar.jumpToDate(this.config.firstDate)
    }

    if (this.currentXhrRequest && this.currentXhrRequest.readyState < XMLHttpRequest.DONE) {
      this.currentXhrRequest.abort()
    }

    this.currentXhrRequest = coreCommon.createXhr('POST', this.config.ajaxUrl)
    this.currentXhrRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')

    this.currentXhrRequest.onreadystatechange = () => {
      if (this.currentXhrRequest && this.currentXhrRequest.readyState === XMLHttpRequest.DONE && this.currentXhrRequest.status === 200) {
        let dates = JSON.parse<Array<unknown>>(this.currentXhrRequest.response as string)
        dates = Array.isArray(dates) ? dates : []

        if (!this.isPluginInit) {
          this.isPluginInit = true
        }

        this.calendar.calendar?.set('enable', dates)

        if (dates.length === 0) {
          this.calendar.calendar?.set('disable', [() => true])
        }
      }
    }

    this.currentXhrRequest.send(
      Object.keys(ajaxData)
        .map((key) => `${key}=${ajaxData[key] as string}`)
        .join('&')
    )
  }
}

export default (element: string | HTMLElement, userConfig?: CalendarConfig): Calendar => {
  return new Calendar(element, userConfig)
}
