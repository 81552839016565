'use strict'

import elementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'
import SearchCalendar from '@ui/Search/Calendar/component'

import type { Calendar as SearchCalendarType, NightsSelectionPlugin, PluginsConfig } from '@ui/Search/Calendar/component'

const calendarElementId = 'search_offers_checkInDate'

const initCalendar = (): SearchCalendarType | void => {
  const inputEl = document.querySelector<HTMLElement>(`#${calendarElementId}`)

  if (!inputEl) {
    return
  }

  const calendar = SearchCalendar(document.getElementById('search-form-calendar') as HTMLElement, {
    currentLocale: document.body.getAttribute('data-locale')?.split('_'),
    closeOnDateChange: true,
    callbacks: {
      onChange: [
        (calendar) => {
          calendar.element.closest('.search-form__block')?.classList.add('dca-form__field--filled')
        },
      ],
      onOpen: [
        (calendar) => {
          calendar.element.closest('.search-form__block')?.classList.add('dca-form__field--focused')
        },
      ],
      onClose: [
        (calendar) => {
          calendar.element.closest('.search-form__block')?.classList.remove('dca-form__field--focused')
        },
      ],
    },
    pluginsConfig: {
      nightsSelection: (calendar) => ({
        onValueChange: (value) => {
          if (calendar.element.hasAttribute('data-night-field')) {
            const nightFieldName = calendar.element.getAttribute('data-night-field')
            const nightFieldEl = document.querySelector<HTMLInputElement>(`[name="${nightFieldName}"]`)

            if (nightFieldEl && nightFieldEl.value !== value.toString()) {
              nightFieldEl.value = value.toString()
            }
          }
        },
      }),
    } as {
      nightsSelection: PluginsConfig<NightsSelectionPlugin['config']>
    },
  })

  if (elementPropertiesManager.hasProperty(inputEl, 'calendar')) {
    elementPropertiesManager.removeProperty(inputEl, 'calendar')
  }

  elementPropertiesManager.addProperty(inputEl, 'calendar', calendar)

  return calendar
}

export default function (): void {
  const calendar = initCalendar()

  window.addEventListener('scroll', () => {
    if (calendar && calendar.isVisible) {
      calendar.close()
    }
  })
}
